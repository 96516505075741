import React from 'react';
import { subscribe, unsubscribe} from 'pusher-redux';
import {
    CONVERSATION_CHAT_ROOM_EVENT,
    NEW_MESSAGE_EVENT,
    UPDATE_CONVERSATION_EVENT,
    UPDATE_MESSAGE_EVENT
} from "@/shared/conversation/actions";
import {NEW_ENCOUNTER_EVENT} from "@/shared/encounter/actions";
import {ADD_UPDATE_TRIGGER_EVENT} from "@/shared/rules/actions";
import {
    PRESENCE_MEMBER_ADDED_EVENT,
    PRESENCE_MEMBER_REMOVED_EVENT,
    PRESENCE_SUBSCRIPTION_SUCCEEDED_EVENT
} from "@/shared/user/actions";

export default class RealtimeSubscriptions extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.subscribe = this.subscribe.bind(this);
        this.unsubscribe = this.unsubscribe.bind(this);
        this.channelName = "presence-" + props.tenantId;
        this.logoutChannelName = "presence-" + props.userId; // New channel name
    }

    componentWillMount() {
        this.subscribe();
    }

    componentWillUnmount() {
        this.unsubscribe();
    }

    // upon receiving event 'some_event' for channel 'some_channel' pusher-redux is going to dispatch action NEW_ORDER
    // add additional params which will be merged into pusherAction and dispatched along with it
    // you can bind multiple actions for the same event and it's gonna dispatch all of them
    subscribe() {
        // your additionalParams
        const additionalParams = () => {}

        subscribe(this.channelName, 'NEW_MESSAGE', NEW_MESSAGE_EVENT, additionalParams);
        subscribe(this.channelName, 'CONVERSATION_CHAT_ROOM_EVENT', CONVERSATION_CHAT_ROOM_EVENT, additionalParams);
        subscribe(this.channelName, 'UPDATE_MESSAGE', UPDATE_MESSAGE_EVENT, additionalParams);
        subscribe(this.channelName, 'UPDATE_CONVERSATION', UPDATE_CONVERSATION_EVENT, additionalParams);
        subscribe(this.channelName, 'NEW_ENCOUNTER_EVENT', NEW_ENCOUNTER_EVENT, additionalParams);
        subscribe(this.channelName, 'ADD_UPDATE_TRIGGER_EVENT', ADD_UPDATE_TRIGGER_EVENT, additionalParams);
        subscribe(this.channelName, 'TOPNAV_EVENT', 'TOPNAV_EVENT', additionalParams);
        subscribe(this.channelName, 'TOPNAV_RESET_EVENT', 'TOPNAV_RESET_EVENT', additionalParams);
        subscribe(this.logoutChannelName, 'BACK_CHANNEL_LOGOUT_EVENT', 'BACK_CHANNEL_LOGOUT_EVENT', additionalParams);

        subscribe(this.channelName, 'pusher:subscription_succeeded', PRESENCE_SUBSCRIPTION_SUCCEEDED_EVENT, additionalParams);
        subscribe(this.channelName, 'pusher:member_added', PRESENCE_MEMBER_ADDED_EVENT, additionalParams);
        subscribe(this.channelName, 'pusher:member_removed', PRESENCE_MEMBER_REMOVED_EVENT, additionalParams);
        // access it within the data object = {
        //  type: String,
        //  channel: String,
        //  event: String,
        //  data: Object,
        //  additionalParams: Any
        // }
    }

    unsubscribe() {
        unsubscribe(this.channelName, 'NEW_MESSAGE', NEW_MESSAGE_EVENT);
        unsubscribe(this.channelName, 'UPDATE_MESSAGE', UPDATE_MESSAGE_EVENT);
        unsubscribe(this.channelName, 'UPDATE_CONVERSATION', UPDATE_CONVERSATION_EVENT);
        unsubscribe(this.channelName, 'NEW_ENCOUNTER_EVENT', NEW_ENCOUNTER_EVENT);
        unsubscribe(this.channelName, 'ADD_UPDATE_TRIGGER_EVENT', ADD_UPDATE_TRIGGER_EVENT);
        unsubscribe(this.channelName, 'TOPNAV_EVENT', 'TOPNAV_EVENT');
        unsubscribe(this.channelName, 'TOPNAV_RESET_EVENT', 'TOPNAV_RESET_EVENT');
        unsubscribe(this.logoutChannelName, 'BACK_CHANNEL_LOGOUT_EVENT', 'BACK_CHANNEL_LOGOUT_EVENT');

        unsubscribe(this.channelName, 'pusher:subscription_succeeded', PRESENCE_SUBSCRIPTION_SUCCEEDED_EVENT);
        unsubscribe(this.channelName, 'pusher:member_added', PRESENCE_MEMBER_ADDED_EVENT);
        unsubscribe(this.channelName, 'pusher:member_removed', PRESENCE_MEMBER_REMOVED_EVENT);
    }

    render(){
        return null;
    }
}