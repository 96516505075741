import React from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import {API, Auth, Cache, Hub} from 'aws-amplify';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useDispatch, useSelector} from 'react-redux';
import 'mapbox-gl/dist/mapbox-gl.css';
import {ThemeProvider} from '@material-ui/core/styles';
import {EidAuthContext} from './eid/EIDAuthContext';
import {FEDERATED_PATH} from '@/common/CacheKeys';
import Gainsight from "@/components/Gainsight";
import FullStory from "@/components/FullStory";
import AppLayout from "@/AppLayout";
import {fetchUserMe} from '@/shared/user/actions';
import '@/styles/styles.sass';
import theme from '@/styles/theme';
import {CircularProgress} from "@material-ui/core";
import _ from "lodash";
import {resetRedirectHome} from "./shared/user/actions";

function App(props) {
    const [user, setUser] = React.useState(null);
    const {eidUser, eidUserSignOut} = React.useContext(EidAuthContext);
    const [authState, setAuthState] = React.useState({
        isAuthenticated: false,
        needsVerification: false,
        authenticatedUser: {},
        authLoading: true
    });
    const aboutMe = useSelector(state => state.user.aboutMe);
    const shouldLogout = useSelector(state => state.logout.shouldLogout);
    const inActiveUser = useSelector(state => state.logout.inActiveUser);
    const shouldRedirectHome = useSelector(state => state.logout.shouldRedirectHome);
    const dispatch = useDispatch();

    React.useEffect(() => {
        if (shouldRedirectHome) {
            window.location.href = '/';
            dispatch(resetRedirectHome());
        }
        if (shouldLogout && eidUser) {
            setAuthState({
                authLoading: false,
                isAuthenticated: false,
                authenticatedUser: {},
                needsVerification: false
            })
            eidUserSignOut();
        }
    }, [shouldLogout, shouldRedirectHome]);

    React.useEffect(() => {
        if (shouldLogout && eidUser) {
            return;
        }
        authenticate()
        Hub.listen('auth', (data) => {
            const {payload} = data;
            if (!payload.data) {
                return;
            }
            authenticate();
        });

        Hub.listen('verify', (data) => {
            authenticate();
        });
    }, [eidUser]);

    React.useEffect(() => {
        if (shouldLogout && eidUser) {
            return;
        }
        if (inActiveUser) {
            setAuthState({
                authLoading: false,
                isAuthenticated: false,
                authenticatedUser: {},
                needsVerification: false
            })
            localStorage.setItem("logout_message", "User is inactive, please contact your administrator.");
            if (eidUser && !_.isEmpty(eidUser)) {
                eidUserSignOut();
            } else {
                handleLogout();
            }
        }
        if (aboutMe != null && aboutMe.user != null) {
            const verifyUserLegacySignIn = async () => {
                setAuthState((prevState) => ({
                    ...prevState,
                    authLoading: true,
                }));
                let needsLogout = false;
                if (!eidUser && _.isEmpty(eidUser)) {
                    needsLogout = await verifyUserIfEidEnabled(aboutMe.user.email);
                    if (needsLogout) {
                        setAuthState({
                            authLoading: false,
                            isAuthenticated: false,
                            authenticatedUser: {},
                            needsVerification: false,
                        });
                        localStorage.setItem("logout_message", "Please login with your PowerDMS SSO account.");
                        handleLogout();
                        return;
                    }
                }
                setAuthState({
                    authLoading: false,
                    isAuthenticated: true,
                    authenticatedUser: user,
                    needsVerification: false,
                });
            };
            verifyUserLegacySignIn();
        }
    }, [aboutMe]);

    const verifyUserIfEidEnabled = async (userName) => {
        if (!userName) {
            return false;
        }
        try {
            const encodedUsername = encodeURIComponent(userName);
            const resp = await API.get('Unauthorized', `/u/${encodedUsername}/whoami`);
            if (resp?.F === 'P') {
                return true;
            }
        } catch (error) {
            console.error('Verification failed:', error);
            return false;
        }
        return false;
    };

    const authenticate = () => {
        if (eidUser) {
            dispatch(fetchUserMe(eidUser));
            setUser(eidUser);
            return;
        }
        Auth.currentAuthenticatedUser({bypassCache: true})
            .then((currentAuthenticatedUser) => {
                dispatch(fetchUserMe());
                setUser(currentAuthenticatedUser);
            })
            .catch((ex) => {
                setAuthState({
                    authLoading: false,
                    isAuthenticated: false,
                    authenticatedUser: {},
                    needsVerification: false
                })
            })

    }

    const handleLogout = () => {
        let path = Cache.getItem(FEDERATED_PATH);
        Cache.clear();
        Auth.signOut()
            .then(data => {
                if (path) {
                    window.location = "/" + path + "/auth"
                } else {
                    window.location = "/auth";
                }
            })
            .catch(err => console.log(err));
    }


    if (authState.authLoading || ((eidUser && !_.isEmpty(eidUser)) && !aboutMe.user)) {
        return (
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh'}}>
                <CircularProgress size="5rem"/>
            </div>
        );
    }
    const childProps = {
        isAuthenticated: authState.isAuthenticated,
        authenticatedUser: authState.authenticatedUser,
        needsVerification: authState.needsVerification,
        handleLogout: handleLogout,
    };

    return (
        <Router>
            <Gainsight/>
            <FullStory/>
            <ToastContainer theme={theme}/>
            <ThemeProvider theme={theme}>
                <AppLayout childProps={childProps}/>
            </ThemeProvider>
        </Router>);
}


export default App;