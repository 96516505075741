import { API } from 'aws-amplify';
import {searchClientFailure, searchClients, searchClientSuccess} from "../client/actions";
import _ from "lodash";

export const PRESENCE_SUBSCRIPTION_SUCCEEDED_EVENT = 'PRESENCE_SUBSCRIPTION_SUCCEEDED_EVENT';
export const PRESENCE_MEMBER_ADDED_EVENT = 'PRESENCE_MEMBER_ADDED_EVENT';
export const PRESENCE_MEMBER_REMOVED_EVENT = 'PRESENCE_MEMBER_REMOVED_EVENT';

export const GET_USER_ME = 'GET_USER_ME';
export const requestUserMe = () => dispatch => {
	dispatch({
		type : GET_USER_ME
	});
}

export const GET_USER_ME_SUCCESS = 'GET_USER_ME_SUCCESS';
export const requestUserMeSuccess = (json) => dispatch => {
	dispatch({
		type : GET_USER_ME_SUCCESS,
		payload: json,
		receivedAt: Date.now()
	});
}

export const TOGGLE_THEME = 'TOGGLE_THEME';
export const toggleTheme = () => dispatch => {
	dispatch({
		type : TOGGLE_THEME
	});
}

export const TOGGLE_THEME_SUCCESS = 'TOGGLE_THEME_SUCCESS';
export const toggleThemeSuccess = (json) => dispatch => {
	dispatch({
		type : TOGGLE_THEME_SUCCESS,
		payload: json,
		receivedAt: Date.now()
	});
}

export const GET_USER_ME_FAILURE = 'GET_USER_ME_FAILURE';
export const BACK_CHANNEL_LOGOUT_EVENT = 'BACK_CHANNEL_LOGOUT_EVENT';
export const INACTIVE_USER_LOGOUT = 'INACTIVE_USER_LOGOUT';
export const RESET_REDIRECT_HOME = 'RESET_REDIRECT_HOME';

export const requestUserMeFailure = () => dispatch => {
	dispatch({
		type : GET_USER_ME_FAILURE
	});
}

export const backChannelLogout = () => dispatch => {
	dispatch({
		type : BACK_CHANNEL_LOGOUT_EVENT
	});
}

export const inActiveUserLogout = () => dispatch => {
	dispatch({
		type : INACTIVE_USER_LOGOUT
	});
}

export const resetRedirectHome = () => dispatch => {
	dispatch({
		type : RESET_REDIRECT_HOME
	});
}

export const GET_USER_SYSTEM_PRIVILEGES = 'GET_USER_SYSTEM_PRIVILEGES';
export const requestUserSystemPrivileges = () => dispatch => {
	dispatch({
		type : GET_USER_SYSTEM_PRIVILEGES
	});
}

export const GET_USER_SYSTEM_PRIVILEGES_SUCCESS = 'GET_USER_SYSTEM_PRIVILEGES_SUCCESS';
export const requestUserSystemPrivilegesSuccess = (json) => dispatch => {
	dispatch({
		type : GET_USER_SYSTEM_PRIVILEGES_SUCCESS,
		payload: json,
		receivedAt: Date.now()
	});
}

export const GET_USER_SYSTEM_PRIVILEGES_FAILURE = 'GET_USER_SYSTEM_PRIVILEGES_FAILURE';
export const requestUserSystemPrivilegesFailure = () => dispatch => {
	dispatch({
		type : GET_USER_SYSTEM_PRIVILEGES_FAILURE
	});
}

export const INVITE_USER = 'INVITE_USER';
export const inviteUser = () => dispatch => {
	dispatch({
		type : INVITE_USER
	});
}

export const INVITE_USER_SUCCESS = 'INVITE_USER_SUCCESS';
export const inviteUserSuccess = (json) => dispatch => {
	dispatch({
		type : INVITE_USER_SUCCESS,
		payload: json,
		receivedAt: Date.now()
	});
}

export const INVITE_USER_FAILURE = 'INVITE_USER_FAILURE';
export const inviteUserFailure = (err) => dispatch => {
	dispatch({
		type : INVITE_USER_FAILURE,
		error: err
	});
}

export const INVITE_USER_CLEAR = "INVITE_USER_CLEAR";
export const inviteUserClear = () => dispatch => {
	dispatch({
		type : INVITE_USER_CLEAR
	});
}

export const LIST_PROVIDERS = 'LIST_PROVIDERS';
export const listProviders = () => dispatch => {
	dispatch({
		type : LIST_PROVIDERS
	});
}

export const SEARCH_PROVIDERS = 'SEARCH_PROVIDERS';
export const searchProviders = () => dispatch => {
	dispatch({
		type : SEARCH_PROVIDERS
	});
}

export const LIST_PROVIDERS_FAILURE = 'LIST_PROVIDERS_FAILURE';
export const listProvidersFailure = (err) => dispatch => {
	dispatch({
		type : LIST_PROVIDERS_FAILURE,
		error: err
	});
}

export const LIST_PROVIDERS_SUCCESS = "LIST_PROVIDERS_SUCCESS";
export const listProvidersSuccess = (json) => dispatch => {
	dispatch({
		type : LIST_PROVIDERS_SUCCESS,
		payload: json,
		receivedAt: Date.now()
	});
}

export const SEARCH_PROVIDERS_FAILURE = 'SEARCH_PROVIDERS_FAILURE';
export const searchProvidersFailure = (err) => dispatch => {
	dispatch({
		type : SEARCH_PROVIDERS_FAILURE,
		error: err
	});
}

export const SEARCH_PROVIDERS_SUCCESS = "SEARCH_PROVIDERS_SUCCESS";
export const searchProvidersSuccess = (json) => dispatch => {
	dispatch({
		type : SEARCH_PROVIDERS_SUCCESS,
		payload: json,
		receivedAt: Date.now()
	});
}

export const SEARCH_PROVIDERS_GROUPS_SUCCESS = "SEARCH_PROVIDERS_GROUPS_SUCCESS";
export const searchProviderGroupsSuccess = (json) => dispatch => {
	dispatch({
		type : SEARCH_PROVIDERS_GROUPS_SUCCESS,
		payload: json,
		receivedAt: Date.now()
	});
}
export const LIST_ROLES = 'LIST_ROLES';
export const listRoles = () => dispatch => {
	dispatch({
		type : LIST_ROLES
	});
}

export const LIST_ROLES_SUCCESS = "LIST_ROLES_SUCCESS";
export const listRolesSuccess = (json) => dispatch => {
	dispatch({
		type : LIST_ROLES_SUCCESS,
		payload: json,
		receivedAt: Date.now()
	});
}

export const LIST_FEATURES_SUCCESS = "LIST_FEATURES_SUCCESS";
export const listFeaturesSuccess = (json) => dispatch => {
	dispatch({
		type : LIST_FEATURES_SUCCESS,
		payload: json,
	});
}

export const LIST_FEATURES_ERROR = "LIST_FEATURES_ERROR";
export const listFeaturesError = (error) => dispatch => {
	dispatch({
		type : LIST_FEATURES_ERROR,
		payload: error,
	});
}

export function fetchUserMe(eidUser) {

  return function(dispatch) {

    dispatch(requestUserMe())

    return API.get('Core', '/api/v1/users/me')
      .then(response => {
      	dispatch(requestUserMeSuccess(response))
      },
      	error => {
			dispatch(requestUserMeFailure(error))
			if(error?.response?.status === 400 && error?.response?.data?.message === "User is inactive"){
				dispatch(inActiveUserLogout());
			}
			if (eidUser && !_.isEmpty(eidUser)) {
				dispatch(backChannelLogout());
				localStorage.setItem("logout_message", "Authentication failed. Please sign in again.");
			}
      	}
      );
  }
}

export function doToggleTheme() {

	return function(dispatch) {

		dispatch(requestUserMe())

		return API.post('Core', '/api/v1/users/toggle-theme')
			.then(response => {
					dispatch(toggleThemeSuccess(response))
				},
				error => {
					console.log('An error occurred.', error)
				}
			);
	}
}

export function fetchSystemPrivileges() {

  return function(dispatch) {

    dispatch(requestUserSystemPrivileges())

    return API.get('Core', '/api/v1/users/privileges')
      .then(response => {
      	dispatch(requestUserSystemPrivilegesSuccess(response))
      },
      	error => {
      		console.log('An error occurred.', error)
      	}
      );
  }
}

export function createUser(user) {

  return function(dispatch) {

    dispatch(inviteUser())

    return API.post('Core', '/api/v1/users/provider', {body:user})
      .then(response => {
      	dispatch(inviteUserSuccess(response))
      },
      	error => {
      		dispatch(inviteUserFailure(error.response.data.message));
      	}
      );
  }
}

export function fetchProvidersList() {
	return function (dispatch) {
		dispatch(listProviders())
		return API.get('Core', '/api/v1/users/search')
			.then(response => {
					dispatch(listProvidersSuccess(response))
				},
				error => {
					dispatch(listProvidersFailure(error.response.data.message));
				}
			);

	}
}

export function fetchProvidersSearch(page, size, search, withoutPhone) {
	return function(dispatch){
		dispatch(searchProviders())
		return API.get('Core', '/api/v1/users/provider/search', {
			queryStringParameters: {
				page: page,
				size: size,
				search: search,
				withoutPhone: withoutPhone
			}
		})
			.then(response => {
					if(withoutPhone){
						dispatch(searchProviderGroupsSuccess(response))
					}else{
						dispatch(searchProvidersSuccess(response))
					}
				},
				error => {
					dispatch(searchProvidersFailure(error.response.data.message));
				})
	}
}

export function fetchRoles() {
	return function (dispatch) {
		dispatch(listRoles())
		return API.get('Core', '/api/v1/role')
			.then(response => {
					dispatch(listRolesSuccess(response))
				},
				error => {}
			);

	}
}

export function refreshTenantFeatures() {
	return function(dispatch) {
		return API.get('Core', '/api/v1/features')
				.then((response) => {
					dispatch(listFeaturesSuccess(response))
				})
				.catch((error) => {
					dispatch(listFeaturesError(""));
				});
	}
}
