export const createAppSwitcherConfig = () => ({
    isEnabled: true,
    defaultOptionType: "Products",
    options: [
        {
            id: "Products",
            clickable: {
                type: "button"
            },
            categories: [
                {
                    name: "App Menu",
                    products: [
                        {
                            name: "PowerLINE",
                            clickable: {
                                type: "anchor",
                                onClick: () => {
                                    window.location.href = "https://wellness.qa.neogov.net/";
                                }
                            }
                        },
                        {
                            name: "PowerPolicy",
                            clickable: {
                                type: "anchor",
                                onClick: () => {
                                    window.location.href = "https://qa2.powerdms.net/ui/app-switch-landing.aspx";
                                }
                            }
                        },
                        {
                            name: "PowerReady",
                            clickable: {
                                type: "anchor",
                                onClick: () => {
                                    window.location.href = "https://powerfto.qa.neogov.net/eid-appswitch";
                                }
                            }
                        },
                        {
                            name: "PowerTime",
                            clickable: {
                                type: "anchor",
                                onClick: () => {
                                    window.location.href = "https://sandbox.planitschedule.com/eid-appswitch.aspx";
                                }
                            }
                        },
                        {
                            name: "Professional Standards",
                            clickable: {
                                type: "anchor",
                                onClick: () => {
                                    window.location.href = "https://core-ci.dev.pss.powerdms.net/";
                                }
                            }
                        },
                        {
                            name: "Schedule",
                            clickable: {
                                type: "anchor",
                                onClick: () => {
                                    window.location.href = "https://schedule.qa.neogov.net/";
                                }
                            }
                        },
                        {
                            name: "Vetted",
                            clickable: {
                                type: "anchor",
                                onClick: () => {
                                    window.location.href = "https://vetted.qa.neogov.net/";
                                }
                            }
                        }
                    ]
                }
            ]
        },
        {
            id: "Home",
            categories: [],
            clickable: {
                type: "button",
                onClick: () => {
                    window.location.href = "https://unified.qa.neogov.net/signin/sso";
                }
            }
        }
    ]
});