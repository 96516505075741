import {
    canBatchFeature,
    canManageArticles,
    canManageRules,
    canManageSatisfaction, canManageSurveys,
    canManageTasks,
    canManageUsers,
    canParticipateConversations,
    canViewActivity,
    canViewConversations, canViewFeedbackBoard, canViewReports, isCandidateCommsEnabled, isPatientsEnabled
} from "../shared/access";

export const createSubNavConfig = (history, user,setSelectedItemId,selectedItemId) => {
    return {
        isEnabled: true,
        selectedItemId: selectedItemId,
        items: [
            ...(canViewConversations() || canParticipateConversations() ? [{
                title: "Conversations",
                id: "Conversations",
                clickable: {
                    type: "button",
                    anchorHref: "/conversations",
                    anchorTarget: "_self",
                    onClick: () => {
                        setSelectedItemId("Conversations");
                        history.push('/conversations');
                        return {
                            preventOverlayClose: false
                        };
                    },
                },
                items: []
            }] : []),
            ...(canManageUsers() ? [{
                title: "Contacts",
                id: "Contacts",
                clickable: {
                    type: "button",
                    anchorHref: "/clients",
                    anchorTarget: "_self",
                    onClick: () => {
                        setSelectedItemId("Contacts");
                        history.push('/clients');
                        return {
                            preventOverlayClose: false
                        };
                    },
                },
                items: []
            }] : []),
            ...(canViewActivity() ? [{
                title: "Activity",
                id: "Activity",
                clickable: {
                    type: "button",
                    anchorHref: "/activity",
                    anchorTarget: "_self",
                    onClick: () => {
                        setSelectedItemId("Activity");
                        history.push('/activity');
                        return {
                            preventOverlayClose: false
                        };
                    },
                },
                items: []
            }] : []),
            ...(canManageTasks() ? [{
                title: "Tasks",
                id: "Tasks",
                clickable: {
                    type: "button",
                    anchorHref: "/tasks",
                    anchorTarget: "_self",
                    onClick: () => {
                        setSelectedItemId("Tasks");
                        history.push('/tasks');
                        return {
                            preventOverlayClose: false
                        };
                    },
                },
                items: []
            }] : []),
            ...(canViewFeedbackBoard() ? [{
                title: "Feedback",
                id: "Feedback",
                clickable: {
                    type: "button",
                    anchorHref: "/positivity",
                    anchorTarget: "_self",
                    onClick: () => {
                        setSelectedItemId("Feedback");
                        history.push('/positivity');
                        return {
                            preventOverlayClose: false
                        };
                    },
                },
                items: []
            }] : []),
            ...(isCandidateCommsEnabled() ? [{
                title: "Candidates",
                id: "Candidates",
                clickable: {
                    type: "button",
                    anchorHref: "/candidates",
                    anchorTarget: "_self",
                    onClick: () => {
                        setSelectedItemId("Candidates");
                        history.push('/candidates');
                        return {
                            preventOverlayClose: false
                        };
                    },
                },
                items: []
            }] : []),
            ...(canViewReports() ? [{
                title: "Measure",
                id: "Measure",
                clickable: {
                    type: "button",
                    anchorHref: "/measure",
                    anchorTarget: "_self",
                    onClick: () => {
                        setSelectedItemId("Measure");
                        history.push('/measure');
                        return {
                            preventOverlayClose: false
                        };
                    },
                },
                items: []
            }] : []),
            ...(canManageSatisfaction() ? [{
                title: "Satisfaction",
                id: "Satisfaction",
                clickable: {
                    type: "button",
                    anchorHref: "/satisfaction",
                    anchorTarget: "_self",
                    onClick: () => {
                        setSelectedItemId("Satisfaction");
                        history.push('/satisfaction');
                        return {
                            preventOverlayClose: false
                        };
                    },
                },
                items: []
            }] : []),
            ...(user?.userType === "INTERNAL_SUPPORT" ? [{
                title: "Data Sources",
                id: "Data Sources",
                clickable: {
                    type: "button",
                    anchorHref: "/datasources",
                    anchorTarget: "_self",
                    onClick: () => {
                        setSelectedItemId("Data Sources");
                        history.push('/datasources');
                        return {
                            preventOverlayClose: false
                        };
                    },
                },
                items: []
            }] : []),
            {
                title: "Administrative",
                id: "Administrative",
                clickable: {
                    type: "button"
                },
                items: [
                    ...(canManageRules() ? [{
                        title: "Rules",
                        id: "Rules",
                        clickable: {
                            type: "button",
                            onClick: () => {
                                setSelectedItemId("");
                                history.push('/rules');
                                return {
                                    preventOverlayClose: false
                                };
                            }
                        },
                        items: []
                    }] : []),
                    ...(canManageSurveys() ? [{
                        title: "Survey",
                        id: "Survey",
                        clickable: {
                            type: "button",
                            onClick: () => {
                                setSelectedItemId("");
                                history.push('/survey');
                                return {
                                    preventOverlayClose: false
                                };
                            }
                        },
                        items: []
                    }] : []),
                    ...(canManageArticles() ? [{
                        title: "Library",
                        id: "Library",
                        clickable: {
                            type: "button",
                            onClick: () => {
                                setSelectedItemId("");
                                history.push('/library');
                                return {
                                    preventOverlayClose: false
                                };
                            }
                        },
                        items: []
                    }] : []),
                    ...(canManageUsers() && canBatchFeature() ? [{
                        title: "File Import",
                        id: "File Import",
                        clickable: {
                            type: "button",
                            onClick: () => {
                                setSelectedItemId("");
                                history.push('/file-import');
                                return {
                                    preventOverlayClose: false
                                };
                            }
                        },
                        items: []
                    }] : []),
                    ...(isPatientsEnabled() ? [{
                        title: "Providers",
                        id: "Providers",
                        clickable: {
                            type: "button",
                            onClick: () => {
                                setSelectedItemId("");
                                history.push('/providers');
                                return {
                                    preventOverlayClose: false
                                };
                            }
                        },
                        items: []
                    }] : []),
                    ...(isPatientsEnabled() ? [{
                        title: "Services",
                        id: "Services",
                        clickable: {
                            type: "button",
                            onClick: () => {
                                setSelectedItemId("");
                                history.push('/services');
                                return {
                                    preventOverlayClose: false
                                };
                            }
                        },
                        items: []
                    }] : []), {
                        title: "Get Started",
                        id: "Get Started",
                        clickable: {
                            type: "button",
                            onClick: () => {
                                setSelectedItemId("");
                                history.push('/');
                                return {
                                    preventOverlayClose: false
                                };
                            }
                        },
                        items: []
                    }]
            }
        ]
    };
};