import React, {useEffect, useState} from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector} from 'react-redux';
import 'mapbox-gl/dist/mapbox-gl.css';
import CssBaseline from '@material-ui/core/CssBaseline';
import Notification from "@/common/Notification";
import Routes from '@/routes/Routes';
import Sidebar from '@/routes/Sidebar';
import {refreshTenantFeatures} from '@/shared/user/actions';
import '@/styles/styles.sass';
import {enabledFeatureRoutes} from "@/utils/utils";
import {useHistory} from "react-router-dom";
import {doToggleTheme} from "./shared/user/actions";
import {canAdmin, canManageUsers, hasAuthoritiesLoaded, isPlatformLoginEnabled, isTopNavEnabled} from "./shared/access";
import {createSettingsConfig} from "./topnav/SettingsConfig";
import {createHelpConfig} from "./topnav/HelpConfig";
import {createSubNavConfig} from "./topnav/SubNavConfig";
import {createProfileConfig} from "./topnav/ProfileConfig";
import {createAppSwitcherConfig} from "./topnav/AppSwitcherConfig";
import _ from "lodash";

function AppLayout(props) {
    let {childProps} = props;
    let topNavEnabled = isTopNavEnabled();
    const routes = (<Routes childProps={childProps}/>);
    const history = useHistory();
    const dispatch = useDispatch();
    const user = useSelector(state => state?.user?.aboutMe?.user);
    const toggleTheme = () => dispatch(doToggleTheme());
    const [selectedItemId, setSelectedItemId] = useState("");
    const [navbar, setNavbar] = useState(null);
    let currentPath = _.trimStart(history?.location?.pathname, "/");
    const pathToItemIdMap = {
        'conversations': 'Conversations',
        'clients': 'Contacts',
        'activity': 'Activity',
        'tasks': 'Tasks',
        'positivity': 'Feedback',
        'candidates': 'Candidates',
        'measure': 'Measure',
        'datasources': 'Data Sources'
    };

    React.useEffect(() => {
        const unlisten = history.listen((location, action) => {
            if (enabledFeatureRoutes.test(location.pathname)) {
                dispatch(refreshTenantFeatures());
            }
        });

        return () => {
            unlisten();
        };
    }, [history, dispatch]);

    useEffect(() => {
        if (topNavEnabled) {
            if (customElements.get('ce-neogov-top-navbar')) {
                setNavbar(document.querySelector('ce-neogov-top-navbar'));
            } else {
                window.addEventListener('DOMContentLoaded', () => {
                    setNavbar(document.querySelector('ce-neogov-top-navbar'));
                });
            }
            if (_.endsWith(currentPath, 'auth') && hasAuthoritiesLoaded()) {
                history.push('/');
            }
        }
    }, []);

    useEffect(() => {
        if (topNavEnabled && navbar  && user) {
            if (user?.lightTheme) {
                document.body.classList.remove('prefersDarkTheme');
            }
            else {
                document.body.classList.add('prefersDarkTheme');
            }
            if (isPlatformLoginEnabled()) {
                const appSwitcherConfig = createAppSwitcherConfig();
                navbar.appSwitcher.setAppSwitcher(appSwitcherConfig);
            }
            if (canManageUsers()){
                const settingsConfig = createSettingsConfig(history);
                navbar.settings.setSettings(settingsConfig);
            }
            if (canAdmin()){
                const helpConfig = createHelpConfig(history);
                navbar.help.setHelp(helpConfig);
            }
            if (!selectedItemId) {
                const currentLocationPath = currentPath.split('/').pop();
                const itemId = pathToItemIdMap[currentLocationPath];
                if (itemId) setSelectedItemId(itemId);
            }
            const subNavConfig = createSubNavConfig(history,user,setSelectedItemId,selectedItemId);
            const profileConfig = createProfileConfig(user, childProps.handleLogout, toggleTheme);
            navbar.productName = 'ENGAGE';

            navbar.navigation.setNavigation(subNavConfig);
            navbar.profile.setProfile(profileConfig);
        }
    }, [navbar,user,selectedItemId]);

    // If they are not logged in just put in the routes with no sidebar. It will go to Auth flows.
    if (!childProps.isAuthenticated || childProps.needsVerification) {
        return (
            <div className="App logged-out">
                {routes}
            </div>
        );
    }

    // Render full logged in view
    return (
        <div>
            <div>
                {topNavEnabled && <ce-neogov-top-navbar/>}
            </div>
            <div className="appWindow" data-testid="appContainer">
                <CssBaseline/>
                <Notification/>
                {!topNavEnabled && <Sidebar {...childProps} />}
                <main className="contentView">
                    {routes}
                </main>
            </div>
        </div>
    );
}

export default AppLayout;