export const createProfileConfig = (user, handleLogout, toggleThem) => {
    const {firstName, lastName, lightTheme} = user || {};
    return {
        isEnabled: true,
        avatar: {
            firstName: firstName || '',
            lastName: lastName || '',
            photoUrl: null
        },
        profileMenuItems: [
            {
                label: "Logout",
                clickable: {
                    type: "anchor",
                    onClick: () => {
                        handleLogout();
                        return {
                            preventOverlayClose: false
                        };
                    }
                }
            },
            {
                label: lightTheme ? "Use Dark Theme" : "Use Light Theme",
                clickable: {
                    type: "anchor",
                    onClick: () => {
                        toggleThem();
                        return {
                            preventOverlayClose: false
                        };
                    }
                }
            },
        ]
    };
};