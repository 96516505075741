import {INACTIVE_USER_LOGOUT} from "../user/actions";

const initialState = {
    shouldLogout: false,
    inActiveUser:false,
    shouldRedirectHome: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case 'BACK_CHANNEL_LOGOUT_EVENT':
            return { ...state, shouldLogout: true };
        case 'INACTIVE_USER_LOGOUT':
            return { ...state, inActiveUser: true };
        case 'TOPNAV_EVENT':
            return { ...state, shouldRedirectHome: true };
        case 'TOPNAV_RESET_EVENT':
            return { ...state, shouldRedirectHome: true };
        case 'RESET_REDIRECT_HOME':
            return { ...state, shouldRedirectHome: false };
        default:
            return state;
    }
}