export const createHelpConfig = (history) => ({
    isEnabled: true,
    items: [
        {
            title: "help",
            id: "1",
            clickable: {
                type: "button",
                onClick: () => {
                    history.push('/help');
                    return {
                        preventOverlayClose: false
                    };
                },
            }
        }
    ]
});