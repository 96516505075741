export const createSettingsConfig = (history) => ({
    isEnabled: true,
    items: [
        {
            title: "settings",
            id: "1",
            clickable: {
                type: 'button',
                onClick: () => {
                    history.push('/settings');
                    return {
                        preventOverlayClose: false
                    };
                },
            },
        },
    ]
});